<template>
  <p class="h2">
    Ingredients
  </p>
  <form @submit.prevent="onSubmit">
    <section v-for="category in categories" :key="category._id" class="my-4">
      <h3>
        {{ category.name }}
        <small class="text-muted fs-5">{{ category.min }}-{{ category.max }}</small>
      </h3>
      <div class="row">
        <div class="col-md-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Min:</span>
            <input
              v-model="category.min"
              type="number"
              min="0"
              step="1"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Max:</span>
            <input
              v-model="category.max"
              type="number"
              min="0"
              step="1"
              class="form-control"
            >
          </div>
        </div>
      </div>

      <div
        v-for="item in category.values"
        :key="item._id"
        class="input-group mb-3"
      >
        <input
          :id="category.name + '-' + item._id"
          v-model="item.name"
          class="form-control"
          type="text"
          autocomplete="off"
        >
        <div class="input-group-text">
          <input
            class="form-check-input mt-0"
            type="checkbox"
            :checked="item.enabled"
          >
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          v-model="extra[category._id]"
          type="text"
          class="form-control"
          placeholder="..."
        >
      </div>
    </section>

    <button type="submit" class="btn btn-primary">
      Submit
    </button>
  </form>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
//import router from "@/router";

export default {
  name: "Ingredients",
  components: {
    //HelloWorld
  },
  props: {},
  data() {
    return {
      categories: [],
      extra: {},
base: undefined,
      meat: [],
      cheese: [],
      vegetable: [],
      special: [],
      gwoerz: [],
      options: {
        base: {
          min: 1,
          max: 1,
          values: [
            { name: "Margherita", id: 1, weight: "1", enabled: true },
            { name: "Rossa", id: 2, weight: "1", enabled: true },
            { name: "Bianca", id: 3, weight: "1", enabled: true },
            { name: "Bianca Disabled", id: 3, weight: "1", enabled: false },
          ],
        },
        meat: {
          min: 0,
          max: 3,
          values: [
            { name: "Salami", id: 1, weight: "1", enabled: true },
            { name: "Salami Piccante", id: 2, weight: "1", enabled: true },
            { name: "Cotto", id: 3, weight: "1", enabled: true },
            { name: "Crudo", id: 4, weight: "1", enabled: true },
            { name: "Pancetta", id: 5, weight: "1", enabled: true },
            { name: "Porchetta", id: 6, weight: "1", enabled: true },
            { name: "Tirolese", id: 7, weight: "1", enabled: true },
            { name: "Bresaola", id: 8, weight: "1", enabled: true },
            { name: "Salsiccia", id: 9, weight: "1", enabled: true },
          ],
        },
        cheese: {
          min: 0,
          max: 4,
          values: [
            { name: "Mozzarella", id: 1, weight: "1", enabled: true },
            { name: "Bufala", id: 2, weight: "1", enabled: true },
            { name: "Scamorza", id: 3, weight: "1", enabled: true },
            { name: "Ricotta", id: 4, weight: "1", enabled: true },
            { name: "Mascarpone", id: 5, weight: "1", enabled: true },
            { name: "Gorgonzola", id: 6, weight: "1", enabled: true },
            { name: "Parmesan", id: 7, weight: "1", enabled: true },
            { name: "Brie", id: 8, weight: "1", enabled: true },
            { name: "Raclette", id: 9, weight: "1", enabled: true },
            { name: "Fior di Latte", id: 10, weight: "1", enabled: true },
            { name: "Taleggio", id: 11, weight: "1", enabled: true },
          ],
        },
        vegetable: {
          min: 0,
          max: 3,
          values: [
            { name: "Cherry", id: 1, weight: "1", enabled: true },
            { name: "Zucchetti", id: 2, weight: "1", enabled: true },
            { name: "Pepperoni", id: 3, weight: "1", enabled: true },
            { name: "Aubergine", id: 4, weight: "1", enabled: true },
            { name: "Rucola", id: 5, weight: "1", enabled: true },
            { name: "Spinat", id: 6, weight: "1", enabled: true },
            { name: "Oliven", id: 7, weight: "1", enabled: true },
            { name: "Artischocken", id: 8, weight: "1", enabled: true },
            { name: "Zwiebeln", id: 9, weight: "1", enabled: true },
            { name: "Ciccorino Rosso", id: 10, weight: "1", enabled: true },
          ],
        },
        special: {
          min: 0,
          max: 2,
          values: [
            { name: "Champignons", id: 2, weight: "1", enabled: true },
            { name: "Funghi misti", id: 3, weight: "1", enabled: true },
            { name: "Porchini", id: 4, weight: "1", enabled: true },
            {
              name: "Caramelisierte Zwiebeln",
              id: 5,
              weight: "1",
              enabled: true,
            },
            { name: "Ei", id: 6, weight: "1", enabled: true },
            { name: "Ananas", id: 6, weight: "1", enabled: false },
          ],
        },
        gwoerz: {
          min: 0,
          max: 2,
          values: [
            { name: "Origano", id: 1, weight: "1", enabled: true },
            { name: "Basilikum", id: 2, weight: "1", enabled: true },
            { name: "Knoblauchöl", id: 3, weight: "1", enabled: true },
            { name: "Olivenöl", id: 4, weight: "1", enabled: true },
            { name: "Schwarzer Pfeffer", id: 5, weight: "1", enabled: true },
          ],
        },
      },      
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    onSubmit: async function () {

      const extrasCategory = this.categories.filter(category => this.extra[category._id]);
      //console.log('extrasCategory',extrasCategory)
      await Promise.all(extrasCategory.map((category) => {
        return axios.post("api/rest/dao/ingredient",{
            name: this.extra[category._id],
            category: category._id,
          })
      }));
      

      this.categories.forEach(async (category) =>  {

        const ingUpdate = category.values.map(ingredient => {
            return axios.put(`api/rest/dao/ingredient/${ingredient._id}`,{
            name: ingredient.name,
            category: category._id,
          })
        });
        const catUpdate = axios.put(`api/rest/dao/category/${category._id}`,{
            max: category.max,
            min: category.min,
          })
        const out = await Promise.all([...ingUpdate, catUpdate]);
        console.log(out);
      });
      this.load();
    },
    load: async function () {
      //console.log("load", this.id);
      try {
        const colors = [
          "btn-outline-primary",
          "btn-outline-warning",
          "btn-outline-success",
          "btn-outline-danger",
          "btn-outline-info",
          "btn-outline-secondary",
        ];

        this.categories = [];

        const [{ data: categories }, { data: ingredients }] = await Promise.all(
          [
            axios.get("api/rest/dao/category"),
            axios.get("api/rest/dao/ingredient"),
          ]
        );

        this.categories = categories.map((category, index) => {
          category.values = ingredients.filter(
            (i) => i.category == category._id
          );
          category.color = colors[index % colors.length];
          category.type =
            category.min == 1 && category.max == 1 ? "radio" : "checkbox";
          
          return category;
        });


        //console.log("categories", this.categories);
        
      } catch (err) {
        console.error("ERROR::::: ", err);
        this.errors = err;
      }
    },
  },
};
</script>
